/* istanbul ignore file: todo(eran): not running image tests on this file yet */

import React from 'react';
import classNames from 'classnames';
import {ImageUiTpaWrapper} from './ImageUiTpaWrapper';
import autobind from 'auto-bind-es5';
import s from './ProductImage.scss';
import {HoverType, ImageModeId, ImageRatioId, IProduct, ProductType} from '../../../../types/galleryTypes';
import {ImageResizeOptions} from 'wix-ui-tpa';
import * as Icons from '@wix/wixstores-client-common-components/dist/src/icons/dist';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {Carousel, CarouselAriaLabels} from '../../Carousel/Carousel';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export interface IProductImageProps extends IGalleryGlobalProps {
  product: IProduct;
  isMobile: boolean;
  hoverType: string;
  imageRatioId: number;
  imageModeId: number;
  classNames: {thumbnail: string; image: string};
  children?: React.ReactChild[] | React.ReactChild;
  textsMap: {
    digitalProductBadgeAriaLabel: string;
  };
}

interface IProductImageState {
  imageContainerDimensions: {width: number; height: number};
}

export enum DataHook {
  Images = 'product-item-images',
  ProductImage = 'product-image',
  Badge = 'product-badge',
}

export const MAX_CAROUSEL_IMAGES = 5;
export const getProductImageDataHook = (key: number) => `${DataHook.ProductImage}-${key}`;

export const ratioIdToRatioMap = {
  [ImageRatioId._3x2]: 3 / 2,
  [ImageRatioId._4x3]: 4 / 3,
  [ImageRatioId._1x1]: 1,
  [ImageRatioId._3x4]: 3 / 4,
  [ImageRatioId._2x3]: 2 / 3,
  [ImageRatioId._16x9]: 16 / 9,
  [ImageRatioId._9x16]: 9 / 16,
};

export class ProductImageComponent extends React.Component<
  IProductImageProps & IProvidedTranslationProps,
  IProductImageState
> {
  constructor(props) {
    super(props);
    autobind(this);
  }

  private readonly imageContainerRef = React.createRef<HTMLDivElement>();

  public renderDigitalBadge = () => {
    return (
      <div
        className={s.productBadge}
        data-hook={DataHook.Badge}
        aria-label={this.props.textsMap.digitalProductBadgeAriaLabel}>
        <Icons.DigitalProductBadge />
      </div>
    );
  };

  private renderImages() {
    const {
      hoverType,
      imageModeId,
      isMobile,
      product,
      imageRatioId,
      classNames: {thumbnail: externalThumbnailClass, image: externalImageClass},
      globals: {shouldShowImageCarousel},
    } = this.props;
    let showAmountOfPhotos: number = 1;

    const shouldShowSecondaryImageByHoverType = !isMobile && hoverType === HoverType.Alternate;

    if (product.media.length >= 2) {
      if (shouldShowImageCarousel) {
        showAmountOfPhotos = Math.min(product.media.length, MAX_CAROUSEL_IMAGES);
      } else if (shouldShowSecondaryImageByHoverType) {
        showAmountOfPhotos = 2;
      }
    }
    const imageClassNames = classNames(s.productImage, s.productImageNewUiTpa, externalImageClass);
    const thumbnailClass = classNames(externalThumbnailClass, s.productImageWrapper);

    return Array.from({length: showAmountOfPhotos}, (_value, key) => (
      <ImageUiTpaWrapper
        hoverType={hoverType as HoverType}
        mediaItemIndex={key}
        key={key}
        className={thumbnailClass}
        imageClassName={imageClassNames}
        product={product}
        data-hook={getProductImageDataHook(key)}
        resize={imageModeId === ImageModeId.Crop ? ImageResizeOptions.cover : ImageResizeOptions.contain}
        forceAspectRatio={ratioIdToRatioMap[imageRatioId]}
      />
    ));
  }

  public render() {
    const {
      imageRatioId,
      product,
      isMobile,
      globals: {shouldShowImageCarousel, isLiveSiteMode, isPreviewMode},
      t,
    } = this.props;

    const ariaLabels: CarouselAriaLabels = {
      arrowLeft: t('arrowPrevious'),
      arrowRight: t('arrowNext'),
    };

    return (
      <div
        className={s.productImages}
        style={{aspectRatio: ratioIdToRatioMap[imageRatioId].toString()}}
        ref={this.imageContainerRef}
        aria-live={'assertive'}
        data-hook={DataHook.Images}>
        {shouldShowImageCarousel ? (
          <Carousel
            ariaLabels={ariaLabels}
            isMobile={isMobile}
            shouldShowArrowsOnHover={isLiveSiteMode || isPreviewMode}>
            {this.renderImages()}
          </Carousel>
        ) : (
          this.renderImages()
        )}
        {product.productType === ProductType.DIGITAL && this.renderDigitalBadge()}
        {this.props.children}
      </div>
    );
  }
}

export const ProductMedia = withGlobals(withTranslations()(ProductImageComponent));
